<template>
    <b-modal id="bank-account-modal" hide-header hide-footer centered size="md" @hidden="onHidden">
        <div class="modal-header">
            <p class="modal-title">{{ this.form.id ? 'Atualizar' : 'Criar' }} conta bancária </p>
            <Close class="icon" @click="close" />
        </div>
        <main class="content">
            <b-row>
                <b-col class="bank-account-modal--form col-12">
                    <b-form-group class="col-6">
                        <label for="bank-account_name">Nome</label>
                        <b-form-input id="flag_name" type="text" v-model="form.name" placeholder="Nome" />
                        <div v-if="validate && !this.form.name" class="custom-invalid-feedback">Campo obrigatório</div>
                    </b-form-group>
                    <b-form-group class="col-6">
                        <label for="bank-account_description">Descrição</label>
                        <b-form-input id="bank-account_name" type="text" v-model="form.description"
                            placeholder="Descrição" />
                    </b-form-group>
                    <b-form-group class="col-6">
                        <label for="bank-account_balance">Saldo</label>
                        <b-form-input id="bank-account_balance" type="text" v-model="form.account_balance"
                            placeholder="R$ 0,00"
                            @input="atualizarMascara" 
                            />
                        <div v-if="validate && !this.form.account_balance" class="custom-invalid-feedback">Campo obrigatório
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
        </main>
        <div class="wrapper-button">
            <b-button variant="outlined-default" @click="close">
                Cancelar
            </b-button>
            <b-button variant="primary" :disabled="loading" :loading="loading"
                @click="form.id ? updateBankAccount() : createBankAccount()">
                Salvar
            </b-button>
        </div>
    </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
export default {
    props: {
        bankAccount: Object,
        defaultEntry: Object,
        defaultOutband: Object
    },
    components: {
        Close: () => import('@/assets/icons/close.svg'),
    },
    data() {
        return {
            loading: false,
            clinic: getCurrentClinic(),
            validate: false,
            form: {},
        }
    },
    methods: {
        close() {
            this.$bvModal.hide('bank-account-modal')
            this.$emit('hidden')
        },
        onHidden() {
            this.form = {}
            this.validate = false
        },
        async createBankAccount() {
            this.validate = true

            if (!this.form?.name) return
            
            let account_balance_final = this.form.account_balance.replace(/[^\d,]/g, '')
            account_balance_final = account_balance_final.replace(",", ".")

            this.loading = true

            try {
                await this.api.createBankAccount({
                    clinic_id: this.clinic.id,
                    account_balance: parseFloat(account_balance_final),
                    creation_date: this.moment().format('YYYY/MM/DD'),
                    default_bank_account_entries: !this.defaultEntry,
                    default_bank_account_outband: !this.defaultOutband,
                    description: this.form.description ? this.form.description : undefined,
                    id: this.form.id,
                    name: this.form.name,
                    status: !!this.form.status,
                })
                this.$toast.success('Conta bancária criada com sucesso!')
                this.form = {}
                this.close()
            } catch (err) {
                this.$toast.error(err.message)
            } finally {
                this.loading = false
            }
        },
        async updateBankAccount() {
            this.validate = true

            if (!this.form?.name) return
           
            let account_balance_final = this.form.account_balance.replace(/[^\d,]/g, '')
            account_balance_final = account_balance_final.replace(",", ".")   

            this.loading = true

            try {
                await this.api.updateBankAccount({
                    clinic_id: this.clinic_id,
                    id: this.form.id,
                    creation_date: this.moment().format('YYYY-MM-DD'),
                    name: this.form.name,
                    description: this.form.description,
                    account_balance: parseFloat(account_balance_final),
                    status: this.form.status,
                    default_bank_account_entries: !!this.defaultEntry,
                    default_bank_account_outband: !!this.defaultOutband,
                })

                this.$toast.success('Conta bancária atualizada com sucesso!')
                this.close()
            } catch (err) {
                this.$toast.error(err.message)
            } finally {
                this.loading = false
            }
        },
        formatarMoeda(valor) {
            return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        },
        atualizarMascara() {
            if (!this.form.account_balance) return
            const numero = this.form.account_balance.replace(/[^\d]/g, '')
            this.form.account_balance = this.formatarMoeda(numero / 100)
        }
    },
    watch: {
        bankAccount(value) {
            this.form = value || {}
            this.form.account_balance = (this.form.account_balance? this.form.account_balance.toFixed(2) : null)
            this.atualizarMascara()
        },
        'this.form.account_balance': function(novoValor, valorAntigo) {
            if (novoValor !== valorAntigo) {
                this.atualizarMascara()
            }
        }
    },
}
</script>

<style lang="scss">
#bank-account-modal {
    .bank-account-modal--form {
        display: flex;
        flex-wrap: wrap;
    }

    .modal-content {
        border-radius: 8px;

        .modal-body {
            padding: 0 !important;
            margin: 0 !important;

            .modal-header {
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px !important;

                .modal-title {
                    font-family: 'Nunito Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 28px;
                    color: var(--type-active);
                }

                .icon {
                    width: 25px;
                    fill: var(--neutral-500);
                }
            }

            .content {
                padding: 2rem;
            }

            .wrapper-button {
                width: 100%;
                display: inline-flex;
                padding: 0 2rem 1rem 2rem;
                justify-content: flex-end;
                gap: 2rem;
            }
        }
    }
}
</style>
